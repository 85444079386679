import { useEffect } from 'react';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import { useSearchStore } from 'components/advertisementsFilter/features/search/store/useSearchStore';
import { useRedirectByFilter } from 'components/advertisementsFilter/features/search/hooks/useRedirectByFilter';
import { useSyncFilterWithStore } from 'components/advertisementsFilter/features/search/hooks/useSyncFilterWithStore';
import {
    IInitialSearchFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';

interface IReturn {
    onRedirectByFilter: (order?: EOrder) => void;
}

export const useSearch = (
    initialSearchFilterData: IInitialSearchFilterData,
    pagination?: IAdvertisementListingFilterPagination,
    filterDataFromUrl?: FilterDataFromUrlType,
): IReturn => {
    const setCurrentPagination = useSearchStore((store) => store.setCurrentPagination);
    const { syncFilterWithStore } = useSyncFilterWithStore();

    useEffect(() => {
        syncFilterWithStore(filterDataFromUrl);
    }, [
        filterDataFromUrl,
        syncFilterWithStore,
        initialSearchFilterData,
    ]);

    useEffect(() => {
        if (pagination) {
            setCurrentPagination(pagination);
        }
    }, [pagination, setCurrentPagination]);

    const { onRedirectByFilter } = useRedirectByFilter();

    return {
        onRedirectByFilter,
    };
};