import { AxiosResponse } from 'axios';
import { advertisementListingApi } from 'modules/api/client';
import {
    AdvertisementListingDevProjectsDto,
    AdvertisementsListDto,
} from 'modules/api/generated';
import { IAdvertisementListingFilter } from 'modules/api/clients/advertisement/filter/interfaces/IAdvertisementListingFilter';
import { IAdvertisementsList } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementsList';
import { mapAdvertisementsListDtoToIAdvertisementsList } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/mapAdvertisementsListDtoToIAdvertisementsList';
import { mapIAdvertisementListingFilterToAdvertisementListingFilterDto } from 'modules/api/clients/advertisement/filter/helpers/mappers/mapIAdvertisementListingFilterToAdvertisementListingFilterDto';
import { mapIAdvertisementListingFilterDataToAdvertisementListingFilterDataDto } from 'modules/api/clients/advertisement/filter/helpers/mappers/data/mapIAdvertisementListingFilterDataToAdvertisementListingFilterDataDto';
import { IAdvertisementListingFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/data/IAdvertisementListingFilterData';
import { IDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';
import { mapAdvertisementListingDevProjectsDtoToDevProject } from 'modules/api/clients/advertisement/listing/helpers/mappers/devProject/mapAdvertisementListingDevProjectsDtoToDevProject';
import { reduceDevProjectsAccordingToCountMatchesFilter } from 'modules/api/clients/advertisement/listing/helpers/reducers/reduceDevProjectsAccordingToCountMatchesFilter';

export class AdvertisementListing {
    public async getAdvertisements(
        filter: IAdvertisementListingFilter
    ): Promise<IAdvertisementsList> {
        let response: AxiosResponse<AdvertisementsListDto>;
        try {
            response = await advertisementListingApi.getAdvertisements(
                mapIAdvertisementListingFilterToAdvertisementListingFilterDto(
                    filter
                )
            );
        } catch (error) {
            throw new Error('Advertisements were not found');
        }

        if (response.status !== 201) {
            throw new Error('Advertisements were not found');
        }

        const AdvertisementsListDto =
            mapAdvertisementsListDtoToIAdvertisementsList(response.data);

        const { results } = AdvertisementsListDto;

        const reducedDevProjectsResult =
            reduceDevProjectsAccordingToCountMatchesFilter(results);

        return {
            ...AdvertisementsListDto,
            results: reducedDevProjectsResult,
        };
    }

    public async getAdvertisementsTotalCount(
        filter: IAdvertisementListingFilterData
    ): Promise<number> {
        let response: AxiosResponse<number>;

        try {
            response = await advertisementListingApi.getAdvertisementsCount(
                mapIAdvertisementListingFilterDataToAdvertisementListingFilterDataDto(
                    filter
                )
            );
        } catch (error) {
            throw new Error('Cannot get advertisements total count by filter');
        }

        if (response.status !== 201) {
            throw new Error('Cannot get advertisements total count by filter');
        }

        return response.data;
    }

    public async getDevProjects(
        limit: number,
        skip: number
    ): Promise<IDevProject[]> {
        let response: AxiosResponse<AdvertisementListingDevProjectsDto>;

        try {
            response = await advertisementListingApi.getDevProjects(
                limit,
                skip
            );
        } catch (error) {
            throw new Error('Cannot get Dev Projects');
        }

        if (response.status !== 200) {
            throw new Error('Cannot get Dev Projects');
        }

        const {
            data: { projects },
        } = response;

        return projects.map(mapAdvertisementListingDevProjectsDtoToDevProject);
    }
}
