import { create } from 'zustand';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';

type ISearchStore = {
    setPaginationOrder: (order: EOrder) => void;
    currentPagination: IAdvertisementListingFilterPagination;
    setCurrentPagination: (pagination: IAdvertisementListingFilterPagination) => void;
};

export const useSearchStore = create<ISearchStore>((set) => ({
    currentFilter: null,
    currentPagination: { limit: 30, skip: 0, order: EOrder.DEFAULT },
    setCurrentPagination: (pagination) => set(() => ({ currentPagination: pagination })),

    setPaginationOrder: (order) => set((store) => ({
        currentPagination: {
            ...store.currentPagination,
            order,
        }
    }))
}));