import { useCallback, useEffect, useState } from 'react';
import { getCookie, setCookie, CookieValueTypes } from 'cookies-next';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/modules/location/interfaces/IAutocompleteOption';

interface IReturn {
    options: IAutocompleteOption[];
    addCookieOption: (option: IAutocompleteOption) => void;
}

const COOKIE_NAME = 'prev_searched_locations';

export const usePreviouslySearchedLocations = (): IReturn => {
    const [options, setOptions] = useState<IAutocompleteOption[]>([]);
    const optionsCookie = getCookie(COOKIE_NAME);

    const parseCookie = useCallback((cookie: CookieValueTypes): IAutocompleteOption[] => {
        let optionsCookieParsed: IAutocompleteOption[] = [];
        if (cookie) {
            optionsCookieParsed = JSON.parse(cookie.toString());
        }

        return optionsCookieParsed;
    }, []);

    useEffect(() => {
        setOptions(parseCookie(optionsCookie));
    }, [optionsCookie, parseCookie]);

    const addCookieOption = useCallback((option: IAutocompleteOption) => {
        const locationsCookieParsed = parseCookie(getCookie(COOKIE_NAME));

        const locationDuplicity = locationsCookieParsed.find(
            (location) => location.id === option.id);

        if (locationDuplicity) return;

        if (locationsCookieParsed.length >= 5) {
            locationsCookieParsed.pop();
        }

        locationsCookieParsed.unshift(option);

        setCookie(COOKIE_NAME, JSON.stringify(locationsCookieParsed));
    }, [parseCookie]);

    return {
        options,
        addCookieOption,
    };
};
