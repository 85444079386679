import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import { ICategory } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategory';
import { mapLocationSefNameToOldNskSefName } from 'modules/advertisementsFilter/query/mappers/mapLocationSefNameToOldNskSefName';
import { ITransaction } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';
import { categoriesToExcludeFromSefOnly, mapQueryParamCategoryToOld } from 'modules/advertisementsFilter/query/mappers/mapQueryParamCategoryToOld';

type ExtendedParamsType = Pick<
    FilterDataFromUrlType,
    'priceFrom' |
    'priceTo' |
    'areaFrom' |
    'areaTo' |
    'fulltext' |
    'isOnlyNewBuilding' |
    'isOnlyWithVideo'
>;

export class RedirectUrlAndQueryConstructor {

    // TODO: refactor after new listing is 100%
    public createRedirectUrlAndQuery(
        withRedirectToOldListing: boolean,
        paginationOrder: EOrder,
        extendedParams: ExtendedParamsType,
        categories: ICategory[],
        location?: ILocation,
        transaction?: ITransaction,
    ): { pathname: string; queryParams: Record<string, string | string[]> } {
        const pathname: string[] = [];
        const queryParams: {[key: string]: string | string[]} = {};

        if (withRedirectToOldListing) {
            const allCategories = [];

            if (categories.length) {
                allCategories.push(categories[0].id);
            } else {
                allCategories.push(...categories);
            }

            if (allCategories.length === 1) {
                if (!categoriesToExcludeFromSefOnly.includes(allCategories[0])) {
                    pathname.push(categories[0].sefName);
                } else {
                    queryParams.categories = mapQueryParamCategoryToOld(
                        allCategories,
                    );
                }
            } else {
                queryParams.categories = mapQueryParamCategoryToOld(
                    allCategories,
                );
            }
        } else {
            if (categories && categories.length > 1) {
                queryParams.categories = categories.map((category) => category.id.toString());
            } else {
                if (categories[0]) {
                    pathname.push(categories[0].sefName);
                }
            }
        }

        if (location) {
            if (withRedirectToOldListing) {
                pathname.push(
                    mapLocationSefNameToOldNskSefName(location),
                );
            } else {
                pathname.push(location.sefName);
            }
        }

        if (transaction) {
            pathname.push(transaction.sefName);
        }

        if (extendedParams.priceFrom) {
            queryParams.priceFrom = extendedParams.priceFrom.toString();
        } else {
            delete queryParams.priceFrom;
        }

        if (extendedParams.priceTo) {
            queryParams.priceTo = extendedParams.priceTo.toString();
        } else {
            delete queryParams.priceTo;
        }

        if (extendedParams.areaFrom) {
            queryParams.areaFrom = extendedParams.areaFrom.toString();
        } else {
            delete queryParams.areaFrom;
        }

        if (extendedParams.areaTo) {
            queryParams.areaTo = extendedParams.areaTo.toString();
        } else {
            delete queryParams.areaTo;
        }

        if (extendedParams.isOnlyWithVideo) {
            queryParams.onlyWithVideo = '1';
        } else {
            delete queryParams.isOnlyWithVideo;
        }

        if (extendedParams.isOnlyNewBuilding) {
            queryParams.onlyNewbuilding = '1';
        } else {
            delete queryParams.isOnlyNewBuilding;
        }

        if (extendedParams.fulltext) {
            queryParams.fulltext = extendedParams.fulltext;
        } else {
            delete queryParams.fulltext;
        }

        if (EOrder[paginationOrder] && paginationOrder !== EOrder.DEFAULT) {
            queryParams.order = paginationOrder;
        }

        return {
            pathname: pathname.join('/'),
            queryParams,
        };
    }
}