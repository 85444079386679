import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { appUserIsLoggedIn } from 'modules/state/app/state';
import {
    ADVERTISEMENT_NEW_HP_TO_OLD_RESULTS,
    ADVERTISEMENT_SEARCH,
    ADVERTISEMENT_SEARCH_DEFAULT,
    ADVERTISEMENT_SEARCH_OLD
} from 'modules/route/routes';
import { usePathname, useRouter } from 'next/navigation';
import { IGtmEvent } from 'modules/analytics/IAnalytics';
import useLoading from 'modules/state/app/hook/useLoading';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';
import { ELocationType } from 'modules/api/clients/location/enums/type/ELocationType';
import { mapPathnameToOld } from 'modules/advertisementsFilter/query/mappers/mapPathnameToOld';
import { getSearchEvent } from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';
import { getResetEcommerceEvent } from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import { useDataToSef } from 'components/advertisementsFilter/features/search/hooks/useDataToSef';
import { useSearchStore } from 'components/advertisementsFilter/features/search/store/useSearchStore';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { getOldListingRedirectUrl } from 'modules/advertisementsFilter/query/cookie/getOldListingRedirectUrl';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';
import { renameDistrictLocation } from 'components/advertisementsFilter/modules/location/helpers/renameLocation';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';
import { usePreviouslySearchedLocations } from 'components/advertisementsFilter/modules/location/hooks/usePreviouslySearchedLocations';
import { RedirectUrlAndQueryConstructor } from 'modules/advertisementsFilter/redirectUrlAndQueryConstructor/RedirectUrlAndQueryConstructor';

interface IReturn {
    onRedirectByFilter: (order?: EOrder) => void;
}

export const useRedirectByFilter = (): IReturn => {
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();
    const { start: startLoading } = useLoading();

    const router = useRouter();
    const pathname = usePathname();

    const [isAdvertiserLoggedIn] = useRecoilState(appUserIsLoggedIn);
    const { addCookieOption } = usePreviouslySearchedLocations();

    const currentPagination = useSearchStore((store) => store.currentPagination);

    const extendedParams = useExtendedStore((store) => store);
    const selectedLocation = useLocationStore((store) => store.selectedLocation);
    const categoryOptions = useCategoriesStore((store) => store.categoryOptions);
    const selectedCategories = useCategoriesStore((store) => store.selectedCategories);
    const selectedTransaction = useTransactionStore((store) => store.selectedTransaction);

    const { getDataToSef } = useDataToSef();

    const onRedirectByFilter = useCallback(async (order?: EOrder) => {
        const dataToSef = await getDataToSef();

        if (dataToSef.location) {
            const isCity = dataToSef.location.type === ELocationType.CITY;

            addCookieOption({
                id: dataToSef.location.id,
                type: dataToSef.location.type,
                sefName: dataToSef.location.sefName,
                subName: isCity && dataToSef.location.parent?.name,
                name: renameDistrictLocation(dataToSef.location).name,
            });
        }

        // // @TODO - REDIRECT TO OLD NSK - remove when new listing is out
        const isHomePage = pathname === '/';

        const paginationOrder = order ? order : currentPagination?.order;

        const {
            queryParams,
            pathname: newPathname,
        } = new RedirectUrlAndQueryConstructor().createRedirectUrlAndQuery(
            isHomePage,
            paginationOrder,
            {
                ...extendedParams,
                priceFrom: extendedParams.prices.from,
                areaFrom: extendedParams.area.from,
                priceTo: extendedParams.prices.to,
                areaTo: extendedParams.area.to,
            },
            selectedCategories,
            selectedLocation,
            selectedTransaction,
        );

        const events: IGtmEvent[] = [
            getResetEcommerceEvent(),
            getSearchEvent(
                {
                    locationId: selectedLocation?.id,
                    transaction: selectedTransaction?.name,
                    categories: selectedCategories.map((category) => category.name),
                    priceFrom: extendedParams.prices.from,
                    priceTo: extendedParams.prices.to,
                    areaFrom: extendedParams.area.from,
                    areaTo: extendedParams.area.to,
                    onlyNewbuilding: extendedParams.isOnlyNewBuilding,
                    onlyWithVideo: extendedParams.isOnlyWithVideo,
                    fulltext: extendedParams.fulltext,
                },
                dataToSef?.location?.sefName,
                selectedTransaction?.id,
                isHomePage,
                isAdvertiserLoggedIn
            ),
        ];

        gtmSendEvent(...events);

        const pathnameMapped = isHomePage
            ? mapPathnameToOld(newPathname)
            : newPathname;

        let pathnameToRedirect = isHomePage
            ? ADVERTISEMENT_NEW_HP_TO_OLD_RESULTS(pathnameMapped)
            : ADVERTISEMENT_SEARCH(newPathname);


        // @TODO remove when new listing is out
        // NSK-31489 v pripade ze url obsahuje v sebe akykolvek SEF substring tak odstranujeme '/vyhladavanie' z url
        if (pathnameToRedirect !== `${ADVERTISEMENT_SEARCH_OLD}/`) {
            pathnameToRedirect = pathnameToRedirect.replace(
                ADVERTISEMENT_SEARCH_OLD,
                ''
            );
        }

        // @TODO remove when new listing is out
        let oldListingRedirectUrl: string;
        if (isHomePage) {
            oldListingRedirectUrl = getOldListingRedirectUrl(
                dataToSef,
                categoryOptions,
                {
                    order: paginationOrder,
                    fulltext: extendedParams.fulltext,
                    priceFrom: extendedParams.prices.from && extendedParams.prices.from.toString(),
                    priceTo: extendedParams.prices.to && extendedParams.prices.to.toString(),
                    areaFrom: extendedParams.area.from && extendedParams.area.from.toString(),
                    areaTo: extendedParams.area.to  && extendedParams.area.to.toString(),
                    categories: selectedCategories.map((category) => category.id.toString()),
                    onlyNewbuilding: extendedParams.isOnlyNewBuilding ? 'true' : undefined,
                    onlyWithVideo: extendedParams.isOnlyWithVideo ? 'true' : undefined,
                },
            );
        }

        const params = new URLSearchParams();
        Object.entries(queryParams).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(value => params.append(key, value.toString()));
            } else {
                params.append(key, value.toString());
            }
        });

        let windowPath = window.location.pathname + window.location.search;

        // ak mam prazdny filter
        if (windowPath === ADVERTISEMENT_SEARCH_DEFAULT) {
            windowPath = `${windowPath}/`;
        }

        const queryParamsForUrl = params.size > 0 ? `?${params.toString()}`: '';
        const pathnameFinal = isHomePage ? oldListingRedirectUrl : `${pathnameToRedirect}${queryParamsForUrl}`;

        if (windowPath !== pathnameFinal) {
            startLoading();
        }

        router.push(pathnameFinal);
    }, [
        router,
        pathname,
        gtmSendEvent,
        startLoading,
        getDataToSef,
        extendedParams,
        categoryOptions,
        addCookieOption,
        selectedLocation,
        currentPagination,
        selectedCategories,
        selectedTransaction,
        isAdvertiserLoggedIn,
    ]);

    return { onRedirectByFilter };
};