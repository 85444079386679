import {
    SearchFilterHistogramFilterDto,
} from 'modules/api/generated';
import {
    mainCategoryEnumsToDtoRecord
} from 'modules/api/clients/advertisement/filter/constants/records/histogram/category/main/toDto/mainCategoryEnumsToDtoRecord';
import {
    subCategoryEnumsToDtoRecord
} from 'modules/api/clients/advertisement/filter/constants/records/histogram/category/sub/toDto/subCategoryEnumsToDtoRecord';
import {
    IHistogramFilter
} from 'modules/api/clients/advertisement/filter/interfaces/partials/histogram/partials/filter/IHistogramFilter';
import {
    transactionEnumsToDtoRecord
} from 'modules/api/clients/advertisement/filter/constants/records/histogram/transaction/toDto/transactionEnumsToDtoRecord';

export const mapIFilterHistogramFilterToSearchFilterHistogramFilterDto = (
    filter: IHistogramFilter,
): SearchFilterHistogramFilterDto => ({
    ...(!!filter.locationId && ({locationId: filter.locationId})),
    ...(!!filter.transaction && ({
        transaction: transactionEnumsToDtoRecord[filter.transaction],
    })),
    ...(!!filter.categories && !!filter.categories.length && ({
        categories: filter.categories.map((category) =>
            mainCategoryEnumsToDtoRecord[category] || subCategoryEnumsToDtoRecord[category],
        )
    })),
});