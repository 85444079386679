import {AxiosResponse} from 'axios';
import {searchFilterApi} from 'modules/api/client';
import {
    FilterUrlDataDtoResponse,
    HistogramResponseDto,
    SearchFilterDataToSefDtoRequest,
    SearchFilterDataFromSefDtoRequest,
    SearchFilterDataDto
} from 'modules/api/generated';
import {
    mapInitialSearchFilterDataDtoToISearchFilterData
} from 'modules/api/clients/advertisement/filter/helpers/mappers/initialFilter/mapSearchFilterDtoToISearchFilterData';
import {
    mapFilterUrlDataResponseToIFilterUrlData
} from 'modules/api/clients/advertisement/filter/helpers/mappers/filterUrl/mapFilterUrlDataResponseToIFilterUrlData';
import {
    mapIFilterHistogramFilterToSearchFilterHistogramFilterDto
} from 'modules/api/clients/advertisement/filter/helpers/mappers/histogram/filter/mapAdvertisementListingFilterHistogramDtoToAdvertisementListingFilterHistogram';
import {
    mapAdvertisementListingFilterHistogramDtoToAdvertisementListingFilterHistogram
} from 'modules/api/clients/advertisement/filter/helpers/mappers/histogram/mapIAdvertisementListingFilterHistogramToAdvertisementListingFilterHistogramDto';
import { IInitialSearchFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import { IHistogramFilter } from 'modules/api/clients/advertisement/filter/interfaces/partials/histogram/partials/filter/IHistogramFilter';
import { IAdvertisementFilterHistogram } from 'modules/api/clients/advertisement/filter/interfaces/partials/histogram/IFilterHistogram';
import { IFilterUrlData } from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/IFilterUrlData';

export class AdvertisementsFilter {

    public async getAdvertisementsInitialSearchFilter(): Promise<IInitialSearchFilterData> {
        let response: AxiosResponse<SearchFilterDataDto>;

        try {
            response = await searchFilterApi.getInitialSearchFilter();
        } catch (error) {
            throw new Error('Cannot get advertisements initial search filter');
        }

        if (response.status !== 200) {
            throw new Error('Cannot get advertisements initial search filter');
        }

        return mapInitialSearchFilterDataDtoToISearchFilterData(response.data);
    }

    public async getAdvertisementsFilterDataToSef(
        searchFilterDataToSef: SearchFilterDataToSefDtoRequest
    ): Promise<IFilterUrlData> {
        let response: AxiosResponse<FilterUrlDataDtoResponse>;

        try {
            response = await searchFilterApi.searchFilterDataToSef(searchFilterDataToSef);
        } catch (error) {
            throw new Error('Cannot get data to sef');
        }

        if (response.status !== 201) {
            throw new Error('Cannot get data to sef');
        }

        return mapFilterUrlDataResponseToIFilterUrlData(response.data);
    }

    public async getAdvertisementsFilterDataFromSef(
        searchFilterDataFromSef: SearchFilterDataFromSefDtoRequest
    ): Promise<IFilterUrlData> {
        let response: AxiosResponse<FilterUrlDataDtoResponse>;

        try {
            response = await searchFilterApi.searchFilterUrlDataFromSef(searchFilterDataFromSef);
        } catch (error) {
            throw new Error('Cannot get data from sef');
        }

        if (response.status !== 201) {
            throw new Error('Cannot get data from sef');
        }

        return mapFilterUrlDataResponseToIFilterUrlData(response.data);
    }

    public async getAdvertisementsFilterHistogram(
        itemsCount: number,
        histogramFilter: IHistogramFilter,
    ): Promise<IAdvertisementFilterHistogram> {
        let response: AxiosResponse<HistogramResponseDto>;

        try {
            response = await searchFilterApi.searchFilterHistogram(
                itemsCount,
                mapIFilterHistogramFilterToSearchFilterHistogramFilterDto(
                    histogramFilter
                ),
            );
        } catch (error) {
            throw new Error('Cannot get data from sef');
        }

        if (response.status !== 201) {
            throw new Error('Cannot get data from sef');
        }

        return mapAdvertisementListingFilterHistogramDtoToAdvertisementListingFilterHistogram(response.data);
    }
}