import { FC } from 'react';
import { Box } from '@mui/material';
import { EInputs } from 'components/advertisementsFilter/modules/common/enums/EInputs';
import { Popover } from 'components/advertisementsFilter/modules/common/components/popover/Popover';
import { ILocation as ILocationOption } from 'modules/api/clients/location/interfaces/location/ILocation';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { LocationInput } from 'components/advertisementsFilter/modules/location/components/common/input/LocationInput';
import { Autocomplete } from 'components/advertisementsFilter/modules/location/components/common/autocomplete/Autocomplete';
import { CompleteBox } from 'components/advertisementsFilter/modules/location/components/common/completeBox/CompleteBox';
import { usePreviouslySearchedLocations } from 'components/advertisementsFilter/modules/location/hooks/usePreviouslySearchedLocations';

interface ILocation {
    isInFocus: boolean;
    isHovered: boolean;
    isPopoverOpen: boolean;
    onClosePopover: () => void;
    popoverAnchorEl: HTMLElement;
    onSetInputInFocus: (value: EInputs) => void;
    onChangeHoverState: (flag: EInputs) => void;
    onOpenPopover: (target: HTMLElement) => void;
    onChangeLocation: (location: ILocationOption) => void;
}

export const Location: FC<ILocation> = ({
    isInFocus,
    isHovered,
    onOpenPopover,
    isPopoverOpen,
    onClosePopover,
    popoverAnchorEl,
    onChangeLocation,
    onSetInputInFocus,
    onChangeHoverState,
}) => {
    const { options: previouslySearchedOptions } =
        usePreviouslySearchedLocations();

    const locationQuery = useLocationStore((store) => store.locationQuery);
    const changeLocationQuery = useLocationStore((store) => store.changeLocationQuery);
    const autocompleteOptions = useLocationStore((store) => store.autocompleteOptions);
    const locationOptionsInitial = useLocationStore((store) => store.locationOptionsInitial);

    const showSuggestions = !!locationQuery && !!autocompleteOptions.length;
    const showLastSearched = !!previouslySearchedOptions.length && !locationQuery;
    const showCompleteBox = !locationQuery && !showLastSearched;

    const suggestionsComponent = (
        <Autocomplete
            width={360}
            withXPadding
            options={autocompleteOptions}
            onChangeLocation={(value) => {
                onChangeLocation(value);
                onClosePopover();
            }}
        />
    );

    const lastSearchedComponent = (
        <Autocomplete
            width={360}
            withXPadding
            title="Naposledy ste hľadali:"
            options={previouslySearchedOptions}
            onChangeLocation={(value) => {
                onChangeLocation(value);
                onClosePopover();
            }}
        />
    );

    const completeBoxComponent = (
        <CompleteBox
            withXPadding
            options={locationOptionsInitial}
            onChangeLocation={(value) => {
                onChangeLocation(value);
                onClosePopover();
            }}
        />
    );

    return (
        <>
            <LocationInput
                label="Lokalita"
                inputWidth={224}
                isInFocus={isInFocus}
                isHovered={isHovered}
                value={locationQuery}
                clearButtonMargin={14}
                onClick={onOpenPopover}
                placeholder="Mesto, obec ..."
                onChange={changeLocationQuery}
                onSetInputInFocus={onSetInputInFocus}
                onChangeHoverState={onChangeHoverState}
                onClearLocation={() => {
                    onChangeLocation(null);
                    changeLocationQuery('');
                }}
            />

            <Popover
                isOpen={isPopoverOpen}
                onClose={() => {
                    onClosePopover();
                    onSetInputInFocus(null);
                }}
                anchorEl={popoverAnchorEl}
                offsetOptions={{
                    x: 68,
                    y: 23,
                }}
            >
                <Box
                    minWidth={300}
                    maxHeight={608}
                    sx={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    {showSuggestions && suggestionsComponent}
                    {showLastSearched && lastSearchedComponent}
                    {showCompleteBox && completeBoxComponent}
                </Box>
            </Popover>
        </>
    );
};
