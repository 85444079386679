import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
    fetchAdvertisementsTotalCount
} from 'components/advertisementsFilter/features/totalCount/api/fetchAdvertisementsTotalCount';
import { useTotalCountStore } from 'components/advertisementsFilter/features/totalCount/store/useTotalCountStore';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';

interface IReturn {
    totalCount: number;
    isFetching: boolean;
}

export const useAdvertisementsTotalCountByFilter = (): IReturn  => {
    const [isFetching, setIsFetching] = useState(false);

    const totalCount = useTotalCountStore((store) => store.totalCount);
    const setTotalCount = useTotalCountStore((store) => store.setTotalCount);

    const area = useExtendedStore((store) => store.area);
    const prices = useExtendedStore((store) => store.prices);
    const fulltext = useExtendedStore((store) => store.fulltext);
    const isOnlyWithVideo = useExtendedStore((store) => store.isOnlyWithVideo);
    const selectedLocation = useLocationStore((store) => store.selectedLocation);
    const isOnlyNewBuilding = useExtendedStore((store) => store.isOnlyNewBuilding);
    const selectedCategories = useCategoriesStore((store) => store.selectedCategories);
    const selectedTransaction = useTransactionStore((store) => store.selectedTransaction);

    const getAdvertisementsTotalCount = useCallback(async () => {
        try {
            setIsFetching(true);
            const totalCount = await fetchAdvertisementsTotalCount({
                categories: selectedCategories.length
                    ? selectedCategories.map((selectedCategory) => selectedCategory.name)
                    : undefined,
                locationId: selectedLocation && selectedLocation.id,
                transaction: selectedTransaction && selectedTransaction.name,
                onlyNewbuilding: isOnlyNewBuilding,
                onlyWithVideo: isOnlyWithVideo,
                priceFrom: prices.from,
                priceTo: prices.to,
                areaFrom: area.from,
                areaTo: area.to,
                fulltext,
            });

            setTotalCount(totalCount);
        } catch (error) {
            setTotalCount(0);
        } finally {
            setIsFetching(false);
        }
    }, [
        area.to,
        fulltext,
        area.from,
        prices.to,
        prices.from,
        setTotalCount,
        isOnlyWithVideo,
        selectedLocation,
        isOnlyNewBuilding,
        selectedCategories,
        selectedTransaction,
    ]);

    const debouncedTotalCountFetch = useDebouncedCallback(getAdvertisementsTotalCount, 250);

    useEffect(() => {
        (async () => {
            await debouncedTotalCountFetch();
        })();
    }, [debouncedTotalCountFetch, getAdvertisementsTotalCount]);

    return {
        isFetching,
        totalCount,
    };
};