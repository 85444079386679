import { SearchFilterDataTransactionDto } from 'modules/api/generated';
import {
    IFilterUrlDataTransaction
} from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/partials/transaction/IFilterUrlDataTransaction';
import { ETransaction } from 'modules/api/clients/advertisement/common/enums/parameters/transaction/ETransaction';

export const mapFilterUrlDataTransactionToIFilterUrlDataTransaction = (
    dto: SearchFilterDataTransactionDto
): IFilterUrlDataTransaction => ({
    id: dto.id,
    sefName: dto.sefName,
    name: ETransaction[dto.name],
});