// pojde prec ked bude novy vypis inzeratov
export enum EBratislavaAndKosiceSefs {
  // Bratislava
  BRATISLAVA_LAMAC = 'bratislava-iv-lamac',
  BRATISLAVA_PODUNAJSKE = 'bratislava-ii-podunajske-biskupice',
  BRATISLAVA_NOVE_MESTO = 'bratislava-iii-nove-mesto',
  BRATISLAVA_DEVIN = 'bratislava-iv-devin',
  BRATISLAVA_KARLOVA_VES = 'bratislava-iv-karlova-ves',
  BRATISLAVA_PETRZALKA = 'bratislava-v-petrzalka',
  BRATISLAVA_CUNOVO = 'bratislava-v-cunovo',
  BRATISLAVA_RACA = 'bratislava-iii-raca',
  BRATISLAVA_DEVINSKA = 'bratislava-iv-devinska-nova-ves',
  BRATISLAVA_ZAHORSKA = 'bratislava-iv-zahorska-bystrica',
  BRATISLAVA_RUZINOV = 'bratislava-ii-ruzinov',
  BRATISLAVA_BRATISLAVA_1 = 'okres-bratislava-i',
  BRATISLAVA_RUSOVCE = 'bratislava-v-rusovce',
  BRATISLAVA_VRAKUNA = 'bratislava-ii-vrakuna',
  BRATISLAVA_JAROVCE = 'bratislava-v-jarovce',
  BRATISLAVA_VAJNORY = 'bratislava-iii-vajnory',
  BRATISLAVA_DUBRAVKA = 'bratislava-iv-dubravka',

  // Kosice
  KOSICE_ZAPAD = 'kosice-ii-zapad',
  KOSICE_HRDINOV = 'kosice-iii-dargovskych-hrdinov',
  KOSICE_DZUNGLA = 'kosice-i-dzungla',
  KOSICE_TAHANOVCE = 'kosice-i-tahanovce',
  KOSICE_NOVA_VES = 'kosice-iii-kosicka-nova-ves',
  KOSICE_LUNIK = 'kosice-ii-lunik-ix',
  KOSICE_MYSLAVA = 'kosice-ii-myslava',
  KOSICE_NAD_JAZEROM = 'kosice-iv-nad-jazerom',
  KOSICE_SIDLISKO_KVP = 'kosice-ii-sidlisko-kvp',
  KOSICE_SIDLISKO_TAHANOVCE = 'kosice-i-sidlisko-tahanovce',
  KOSICE_POLOV = 'kosice-ii-polov',
  KOSICE_SEBASTOVCE = 'kosice-iv-sebastovce',
  KOSICE_BARCA = 'kosice-iv-barca',
  KOSICE_SEVER = 'kosice-i-sever',
  KOSICE_KRASNA = 'kosice-iv-krasna-nad-hornadom',
  KOSICE_LORINCIK = 'kosice-ii-lorincik',
  KOSICE_KAVECANY = 'kosice-i-kavecany',
  KOSICE_SACA = 'kosice-ii-saca',
  KOSICE_VYSNE = 'kosice-iv-vysne-opatske',
  KOSICE_STARE_MESTO = 'kosice-i-stare-mesto',
  KOSICE_PERES = 'kosice-ii-peres',
  KOSICE_JUH = 'kosice-iv-juh',

  // Ine
  OSTROV_PIESTANY = 'ostrov-piestany',
}
