import { SearchFilterDataCategoryDto } from 'modules/api/generated';
import { ICategory } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategory';
import {
    mainCategoryEnumsFromDtoRecord
} from 'modules/api/clients/advertisement/common/constants/records/filter/category/main/fromDto/mainCategoryEnumsFromDtoRecord';
import {
    subCategoryEnumsFromDtoRecord
} from 'modules/api/clients/advertisement/common/constants/records/filter/category/sub/fromDto/subCategoryEnumsFromDtoRecord';

export const mapSearchFilterDataCategoryDtoToICategory = (dto: SearchFilterDataCategoryDto): ICategory => ({
    id: dto.id,
    sefName: dto.sefName,
    ...(!!dto.isMain && ({isMain: dto.isMain})),
    ...(!!dto.parentId && ({parentId: dto.parentId})),
    ...(!!dto.sefName && ({sefName: dto.sefName})),
    name: mainCategoryEnumsFromDtoRecord[dto.name] || subCategoryEnumsFromDtoRecord[dto.name],
});