// pojde prec ked bude novy vypis inzeratov
export const kosiceAndBratislavaIds = [
    // Bratislava
    '100012520',
    '100012510',
    '100012513',
    '100012519',
    '100012518',
    '100012524',
    '100012522',
    '100012514',
    '100012516',
    '100012521',
    '100012511',
    '100012439',
    '100012525',
    '100012512',
    '100012523',
    '100012515',
    '100012517',

    // Kosice
    '100012911',
    '100012912',
    '100012926',
    '100012905',
    '100012913',
    '100012928',
    '100012910',
    '100012920',
    '100012925',
    '100012924',
    '100012923',
    '100012918',
    '100012914',
    '100012906',
    '100012919',
    '100012908',
    '100012904',
    '100012922',
    '100012927',
    '100012907',
    '100012909',
    '100012921',

    // Ine
    '100010601', // - Ostrov, Piestany
    '2J163XQB288yUsTWV1On', // - Ostrov, Piestany
];
