import { FC } from 'react';
import { Box } from '@mui/material';
import {
    InputWrapper
} from 'components/advertisementsFilter/modules/common/components/inputWrapper/InputWrapper';
import { ClearInput } from 'components/advertisementsFilter/modules/common/components/clearInput/ClearInput';
import { EInputs } from 'components/advertisementsFilter/modules/common/enums/EInputs';
import { Input } from 'modules/theme/components/fields/input/Input';

interface ILocationInput {
    label: string;
    value: string;
    isInFocus: boolean;
    isHovered?: boolean;
    placeholder: string;
    isSubFilter?: boolean;
    clearButtonMargin?: number;
    inputWidth: number | string;
    onClearLocation: () => void;
    onClick?: (target: HTMLElement) => void;
    onChange: (selectedValue: string) => void;
    onChangeHoverState?: (value: EInputs) => void;
    onSetInputInFocus?: (value: EInputs) => void;
}

export const LocationInput: FC<ILocationInput> = ({
    label,
    value,
    onClick,
    onChange,
    isInFocus,
    isHovered,
    inputWidth,
    placeholder,
    isSubFilter,
    onClearLocation,
    clearButtonMargin,
    onSetInputInFocus,
    onChangeHoverState,
}) => {
    return (
        <InputWrapper
            isFirst
            isTextBold={!!value}
            isInFocus={isInFocus}
            isHovered={isHovered}
            inputWidth={inputWidth}
            isSubFilter={isSubFilter}
            onMouseHover={(value) => {
                const hoverValue = value ? EInputs.LOCATION : null;

                if (typeof onChangeHoverState !== 'undefined') {
                    onChangeHoverState(hoverValue);
                }
            }}
        >
            <Box
                id='filter.locationInput'
                onClick={(event) => onClick(event.currentTarget)}
                sx={{
                    '& .MuiOutlinedInput-input': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }
                }}
            >
                <Input
                    labelShrink
                    value={value}
                    label={label}
                    autoComplete='none'
                    onChange={onChange}
                    placeholder={placeholder}
                    onFocus={
                        onSetInputInFocus !== undefined
                            ? () => onSetInputInFocus(EInputs.LOCATION)
                            : undefined
                    }
                />

                {value &&
                    <ClearInput
                        onClick={onClearLocation}
                        margin={clearButtonMargin}
                    />
                }
            </Box>
        </InputWrapper>
    );
};