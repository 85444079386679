import { HistogramResponseDto } from 'modules/api/generated';
import {
    IAdvertisementFilterHistogram
} from 'modules/api/clients/advertisement/filter/interfaces/partials/histogram/IFilterHistogram';
import {
    mapHistogramDataItemDtoToIHistogramDataItem
} from 'modules/api/clients/advertisement/filter/helpers/mappers/histogram/item/mapHistogramDataItemDtoToIHistogramItem';

export const mapAdvertisementListingFilterHistogramDtoToAdvertisementListingFilterHistogram = (
    dto: HistogramResponseDto
): IAdvertisementFilterHistogram => ({
    data: dto.data.map(mapHistogramDataItemDtoToIHistogramDataItem),
    defaultStart: dto.defaultStart,
    defaultEnd: dto.defaultEnd,
    median: dto.median,
    start: dto.start,
    avg: dto.avg,
    end: dto.end,
});