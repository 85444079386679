import { SearchFilterDataCategoryDto } from 'modules/api/generated';
import {
    IFilterUrlDataCategory
} from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/partials/category/IFilterUrlDataCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';

export const mapFilterUrlDataCategoryToIFilterUrlDataCategory = (
    dto: SearchFilterDataCategoryDto
): IFilterUrlDataCategory => ({
    id: dto.id,
    isMain: dto.isMain,
    sefName: dto.sefName,
    ...(!!dto.isMain && ({isMain: dto.isMain})),
    ...(!!dto.parentId && ({parentId: dto.parentId})),
    name: ESubCategory[dto.name] || EMainCategory[dto.name],
});