import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    LocationOptions
} from 'components/advertisementsFilter/modules/location/components/common/completeBox/partials/options/Options';

interface ICompleteBox {
    options: ILocation[];
    withXPadding?: boolean;
    onChangeLocation: (location: ILocation) => void;
}

export const CompleteBox: FC<ICompleteBox> = ({
    options,
    onChangeLocation,
}) => {

    return <Box p={{ xs: 0, md: 4 }}>
        <Box mb={1}>
            <Text
                semibold
                variant='label1'
                color={(palette) => palette.labelSecondary}
            >
                Zadajte lokalitu, alebo vyberte z obľúbených:
            </Text>
        </Box>
        <LocationOptions
            options={options}
            onChangeLocation={onChangeLocation}
        />
    </Box>;
};
