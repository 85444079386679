import { create } from 'zustand';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { IAutocompleteOption } from 'components/advertisementsFilter/modules/location/interfaces/IAutocompleteOption';

type LocationStore = {
    locationQuery: string;
    selectedLocation: ILocation;
    locationOptionsInitial:  ILocation[];
    autocompleteOptions: IAutocompleteOption[];
    changeLocationQuery: (query: string) => void;
    setSelectedLocation: (location: ILocation) => void;
    setAutocompleteOptions: (options: IAutocompleteOption[]) => void;
    setLocationOptionsInitial: (optionsInitial: ILocation[]) => void;
};

export const useLocationStore = create<LocationStore>((set) => ({
    locationQuery: '',
    selectedLocation: null,
    autocompleteOptions: [],
    locationOptionsInitial: [],
    changeLocationQuery: (query: string) => set(() => ({ locationQuery: query })),
    setSelectedLocation: (location: ILocation) => set(() => {
        if (!location) {
            return {
                locationQuery: '',
                selectedLocation: null,
            };
        }

        return {
            locationQuery: location.name,
            selectedLocation: location,
        };
    }),
    setAutocompleteOptions: (options: IAutocompleteOption[]) => set(() => ({ autocompleteOptions: options })),
    setLocationOptionsInitial: (optionsInitial: ILocation[]) => set(() => ({ locationOptionsInitial: optionsInitial }))
}));