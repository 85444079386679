import {AdvertisementListingFilterDataDtoTransactionEnum} from 'modules/api/generated';
import {ETransaction} from 'modules/api/clients/advertisement/common/enums/parameters/transaction/ETransaction';

export const transactionEnumsToDtoRecord: Record<ETransaction, AdvertisementListingFilterDataDtoTransactionEnum> = {
    [ETransaction.PURCHASE]: AdvertisementListingFilterDataDtoTransactionEnum.Purchase,
    [ETransaction.HIREOUT]: AdvertisementListingFilterDataDtoTransactionEnum.Hireout,
    [ETransaction.RENT]: AdvertisementListingFilterDataDtoTransactionEnum.Rent,
    [ETransaction.EXCHANGE]: AdvertisementListingFilterDataDtoTransactionEnum.Exchange,
    [ETransaction.AUCTION]: AdvertisementListingFilterDataDtoTransactionEnum.Auction,
    [ETransaction.SALE]: AdvertisementListingFilterDataDtoTransactionEnum.Sale,
};