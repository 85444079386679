import { FC } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/modules/location/interfaces/IAutocompleteOption';
import { baseLocationIconRecord } from 'components/advertisementsFilter/modules/location/records/baseLocationIconRecord';

interface IOption {
    onClick: () => void;
    option: IAutocompleteOption;
}

export const Option: FC<IOption> = ({
    option,
    onClick,
}) => {

    return <Box
        gap={2}
        paddingX={4}
        paddingY={1}
        display='flex'
        onClick={onClick}
        alignItems='center'
        sx={(theme) => ({
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.backgroundSecondary.main
            }
        })}
    >
        <Box
            p={1}
            display='flex'
            borderRadius='12px'
            alignItems='center'
            justifyContent='center'
            bgcolor={(theme) => theme.palette.backgroundTertiary.main}
        >
            <Svg icon component={baseLocationIconRecord[option.sefName]} width={24}/>
        </Box>

        <Box
            display='flex'
            overflow='hidden'
            flexDirection='column'
        >
            <Text
                noWrap
                variant='body2'
            >
                {option.name}
            </Text>

            {option.subName &&
                <Text
                    noWrap
                    variant='label2'
                    color={(palette) => palette.labelSecondary}
                >
                    {option.subName}
                </Text>
            }
        </Box>
    </Box>;
};