import { IFilterUrlData } from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/IFilterUrlData';
import { ICategories } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import { ADVERTISEMENT_NEW_HP_TO_OLD_RESULTS, ADVERTISEMENT_SEARCH_OLD } from 'modules/route/routes';
import { mapPathnameToOld } from 'modules/advertisementsFilter/query/mappers/mapPathnameToOld';
import { createUrlQuery } from 'modules/advertisementsFilter/query/creators/createUrlQuery';
import { EOldNskOrder } from 'modules/advertisementsFilter/query/enums/EOldNskOrder';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';

export const getOldListingRedirectUrl = (
    dataToSef: IFilterUrlData,
    initialCategories: ICategories,
    restParameters: Record<string, EOrder | string | string[]>,
): string => {

    const allInitialCategories = [
        ...initialCategories.main,
        ...initialCategories.sub,
    ];

    const categoriesForNewQuery = [];
    const categoryIdsFromQuery = restParameters.categories as string[];
    const isMoreThanOneCategory = categoryIdsFromQuery && !!categoryIdsFromQuery.length;

    if (isMoreThanOneCategory) {
        categoryIdsFromQuery.forEach((categoryIdFromQuery) => {
            const categoryToAdd = allInitialCategories.find(
                (category) => category.id === parseInt(categoryIdFromQuery),
            );

            if (categoryToAdd) categoriesForNewQuery.push(categoryToAdd.name);
        });
    }

    const order = restParameters.order as EOrder;

    const {
        pathname: pathnameForRedirectUrl,
        queryParams: queryParamsForRedirectUrl,
    } = createUrlQuery(
        {
            locationId: dataToSef.location?.id,
            transaction: dataToSef.transaction?.name,
            categories: categoriesForNewQuery,
        },
        [
            ...initialCategories.main,
            ...initialCategories.sub,
        ],
        dataToSef,
        order,
        true,
    );

    let redirectUrl = ADVERTISEMENT_NEW_HP_TO_OLD_RESULTS(
        mapPathnameToOld(pathnameForRedirectUrl),
    );

    if (redirectUrl !== `${ADVERTISEMENT_SEARCH_OLD}/`) {
        redirectUrl = redirectUrl.replace(
            ADVERTISEMENT_SEARCH_OLD,
            ''
        );
    }

    const queryParamsToAddToUrl: string[] = [];

    const encodedParams = encodeURI('[categories][ids]');
    const queryParamCategories = queryParamsForRedirectUrl.categories as string[];

    if (queryParamCategories && queryParamCategories.length) {
        queryParamsToAddToUrl.push(`p${encodedParams}=${queryParamCategories.join('.')}`);
    }

    if (restParameters.onlyNewbuilding) {
        queryParamsToAddToUrl.push('p[newOnly]=on');
    }

    if (restParameters.onlyWithVideo) {
        queryParamsToAddToUrl.push('p[hasVideo]=on');
    }

    if (restParameters.fulltext && restParameters.fulltext.length) {
        queryParamsToAddToUrl.push(`p[keyword]=${restParameters.fulltext}`);
    }

    if (restParameters.priceFrom) {
        queryParamsToAddToUrl.push(`p[param1][from]=${restParameters.priceFrom}`);
    }

    if (restParameters.priceTo) {
        queryParamsToAddToUrl.push(`p[param1][to]=${restParameters.priceTo}`);
    }

    if (restParameters.areaFrom) {
        queryParamsToAddToUrl.push(`p[param11][from]=${restParameters.areaFrom}`);
    }

    if (restParameters.areaTo) {
        queryParamsToAddToUrl.push(`p[param11][to]=${restParameters.areaTo}`);
    }

    if (
        restParameters.order &&
        !!EOrder[restParameters.order as EOrder] &&
        restParameters.order !== EOrder.DEFAULT
    ) {
        let order = EOldNskOrder.DEFAULT;
        if (restParameters.order === EOrder.NEWEST) order = EOldNskOrder.NEWEST;
        if (restParameters.order === EOrder.PRICE_ASC) order = EOldNskOrder.PRICE_ASC;
        if (restParameters.order === EOrder.PRICE_DESC) order = EOldNskOrder.PRICE_DESC;

        queryParamsToAddToUrl.push(`p[order]=${order}`);
    }

    if (restParameters.page) {
        queryParamsToAddToUrl.push(`p[page]=${restParameters.page}`);
    }

    const queryParams = queryParamsToAddToUrl.join('&');

    return `${redirectUrl}${queryParamsToAddToUrl.length ? `?${queryParams}` : ''}`;
};