import {SearchFilterHistogramFilterDtoCategoriesEnum} from 'modules/api/generated';
import {EMainCategory} from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';

export const mainCategoryEnumsToDtoRecord: Record<EMainCategory, SearchFilterHistogramFilterDtoCategoriesEnum> = {
    [EMainCategory.REAL_ESTATES]: SearchFilterHistogramFilterDtoCategoriesEnum.RealEstates,
    [EMainCategory.APARTMENTS]: SearchFilterHistogramFilterDtoCategoriesEnum.Apartments,
    [EMainCategory.HOUSES]: SearchFilterHistogramFilterDtoCategoriesEnum.Houses,
    [EMainCategory.COTTAGES_AND_CABINS]: SearchFilterHistogramFilterDtoCategoriesEnum.CottagesAndCabins,
    [EMainCategory.LANDS]: SearchFilterHistogramFilterDtoCategoriesEnum.Lands,
    [EMainCategory.SPACES]: SearchFilterHistogramFilterDtoCategoriesEnum.Spaces,
    [EMainCategory.OBJECTS]: SearchFilterHistogramFilterDtoCategoriesEnum.Objects,
    [EMainCategory.SECONDARY_HOUSES]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryHouses,
    [EMainCategory.SECONDARY_LANDS]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryLands,
    [EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryRecreationalProperties,
    [EMainCategory.SECONDARY_SPACES_AND_OBJECTS]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondarySpacesAndObjects,
};
