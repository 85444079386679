import { FC } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Input } from 'modules/theme/components/fields/input/Input';
import {
    Title
} from 'components/advertisementsFilter/modules/extended/components/title/Title';
import { Popover } from 'components/advertisementsFilter/modules/common/components/popover/Popover';
import {
    ContentItemWrapper
} from 'components/advertisementsFilter/modules/extended/components/common/contentItem/wrapper/ContentItemWrapper';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { Autocomplete } from 'components/advertisementsFilter/modules/extended/components/location/partials/autocomplete/Autocomplete';
import { usePreviouslySearchedLocations } from 'components/advertisementsFilter/modules/location/hooks/usePreviouslySearchedLocations';
import { usePopover } from 'components/advertisementsFilter/modules/common/hooks/popover/usePopover';
import PinIcon from 'modules/theme/components/icons/basic/MappinOutline.svg';
import ResetIcon from 'modules/theme/components/icons/basic/ResetCircle.svg';

export const Location: FC = () => {
    const { options: previouslySearchedOptions } =
        usePreviouslySearchedLocations();

    const locationQuery = useLocationStore((store) => store.locationQuery);
    const setSelectedLocation = useLocationStore((store) => store.setSelectedLocation);
    const changeLocationQuery = useLocationStore((store) => store.changeLocationQuery);
    const autocompleteOptions = useLocationStore((store) => store.autocompleteOptions);
    const locationOptionsInitial = useLocationStore((store) => store.locationOptionsInitial);

    const {
        anchorEl: anchorElLocation,
        onOpen: onOpenLocation,
        isOpen: isOpenLocation,
        onClose: onCloseLocation
    } = usePopover();

    const options = locationQuery && autocompleteOptions.length
        ? autocompleteOptions
        : previouslySearchedOptions.length
            ? previouslySearchedOptions
            : locationOptionsInitial;

    const title = locationQuery && autocompleteOptions.length
        ? ''
        : previouslySearchedOptions.length
            ? 'Naposledy ste hľadali:'
            : 'Zadajte lokalitu, alebo vyberte z obľúbených:';

    return <ContentItemWrapper>
        <Box mb={1}>
            <Title text='Lokalita'/>
        </Box>

        <Box
            id='filter.locationInputExtended'
            onClick={(event) => onOpenLocation(event.currentTarget)}
            sx={{
                '& .MuiInputBase-root': { height: 56 },
                '& .MuiFormControl-root .MuiOutlinedInput-root': {
                    height: 56,
                }
            }}
        >
            <Input
                autoComplete='none'
                value={locationQuery}
                endIconStopPropagation
                startIcon={() =>
                    <Svg icon component={PinIcon} width={24}/>
                }
                endIcon={locationQuery && (() =>
                    <Svg
                        icon
                        width={24}
                        component={ResetIcon}
                        onIconClick={() => {
                            changeLocationQuery('');
                            setSelectedLocation(null);
                        }}
                    />
                )}
                placeholder='Mesto, obec ...'
                onChange={changeLocationQuery}
            />
        </Box>

        <Popover
            width={anchorElLocation?.clientWidth}
            isOpen={isOpenLocation}
            borderRadius='8px'
            onClose={() => {
                onCloseLocation();
            }}
            anchorEl={anchorElLocation}
            offsetOptions={{
                x: 0,
                y: 8,
            }}
        >
            <Box
                minWidth={300}
                maxHeight={608}
                border={(theme) => `1px solid ${theme.palette.backgroundPrimary.main}`}
                sx={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                <Autocomplete
                    title={title}
                    options={options}
                    locationQuery={locationQuery}
                    onClick={(location) => {
                        setSelectedLocation(location);
                        onCloseLocation();
                    }}
                />
            </Box>
        </Popover>
    </ContentItemWrapper>;
};