import { AdvertisementsFilter } from 'modules/api/clients/advertisement/filter/AdvertisementsFilter';
import { IFilterUrlData } from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/IFilterUrlData';

export const getQueryFilterDataToSef = async (
    locationId?: string,
    categoryId?: number,
    transactionId?: number,
): Promise<IFilterUrlData> => {
    try {
        return await new AdvertisementsFilter().getAdvertisementsFilterDataToSef({
            locationId,
            categoryId,
            transactionId,
        });
    } catch (error) {
        throw new Error(
            `Failed to fetch filter data to sef format. Error: ${error.message}`
        );
    }
};