import { AdvertisementListingFilterDto } from 'modules/api/generated';
import {
    IAdvertisementListingFilter
} from 'modules/api/clients/advertisement/filter/interfaces/IAdvertisementListingFilter';
import {
    mapIAdvertisementListingFilterDataToAdvertisementListingFilterDataDto
} from 'modules/api/clients/advertisement/filter/helpers/mappers/data/mapIAdvertisementListingFilterDataToAdvertisementListingFilterDataDto';
import {
    mapIAdvertisementListingFilterPaginationToAdvertisementListingFilterPaginationDto
} from 'modules/api/clients/advertisement/filter/helpers/mappers/pagination/mapIAdvertisementListingFilterPaginationToAdvertisementListingFilterPaginationDto';

export const mapIAdvertisementListingFilterToAdvertisementListingFilterDto = (
    filter: IAdvertisementListingFilter
): AdvertisementListingFilterDto => ({
    pagination: mapIAdvertisementListingFilterPaginationToAdvertisementListingFilterPaginationDto(filter.pagination),
    data: mapIAdvertisementListingFilterDataToAdvertisementListingFilterDataDto(filter.data),
});