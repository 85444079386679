import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';

interface IReturn {
    onSearchFilterReset: () => void;
}

export const useSearchFilterReset = (): IReturn => {
    const setAreaTo = useExtendedStore((store) => store.setAreaTo);
    const setPriceTo = useExtendedStore((store) => store.setPriceTo);
    const setFulltext = useExtendedStore((store) => store.setFulltext);
    const setAreaFrom = useExtendedStore((store) => store.setAreaFrom);
    const setPriceFrom = useExtendedStore((store) => store.setPriceFrom);
    const setIsOnlyWithVideo = useExtendedStore((store) => store.setIsOnlyWithVideo);
    const setSelectedLocation = useLocationStore((store) => store.setSelectedLocation);
    const setIsOnlyNewBuilding = useExtendedStore((store) => store.setIsOnlyNewBuilding);

    const setSelectedCategories = useCategoriesStore((store) => store.setSelectedCategories);
    const setSelectedTransaction = useTransactionStore((store) => store.setSelectedTransaction);

    const onSearchFilterReset = (): void => {
        setSelectedLocation(null);
        setSelectedCategories([]);
        setSelectedTransaction(null);

        setAreaTo(0);
        setPriceTo(0);
        setPriceFrom(0);
        setAreaFrom(0);
        setFulltext('');
        setIsOnlyWithVideo(false);
        setIsOnlyNewBuilding(false);
    };

    return {
        onSearchFilterReset,
    };
};