import { FilterUrlDataDtoResponse } from 'modules/api/generated';
import { IFilterUrlData } from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/IFilterUrlData';
import {
    mapFilterUrlDataCategoryToIFilterUrlDataCategory
} from 'modules/api/clients/advertisement/filter/helpers/mappers/filterUrl/category/mapFilterUrlDataCategoryToIFilterUrlDataCategory';
import {
    mapFilterUrlDataTransactionToIFilterUrlDataTransaction
} from 'modules/api/clients/advertisement/filter/helpers/mappers/filterUrl/transaction/mapFilterUrlDataTransactionToIFilterUrlDataTransaction';
import {
    mapFilterUrlDataLocationToIFilterUrlDataLocation
} from 'modules/api/clients/advertisement/filter/helpers/mappers/filterUrl/location/mapFilterUrlDataLocationToIFilterUrlDataLocation';

export const mapFilterUrlDataResponseToIFilterUrlData = (
    dto: FilterUrlDataDtoResponse,
): IFilterUrlData => ({
    ...(!!dto.location && ({location: mapFilterUrlDataLocationToIFilterUrlDataLocation(dto.location)})),
    ...(!!dto.category && ({category: mapFilterUrlDataCategoryToIFilterUrlDataCategory(dto.category)})),
    ...(!!dto.transaction && ({transaction: mapFilterUrlDataTransactionToIFilterUrlDataTransaction(dto.transaction)})),
});