import { ReactNode } from 'react';
import useSession from 'modules/state/app/hook/session/useSession';
import LoginModal from 'components/loginModal/LoginModal';
import useDialog from 'modules/dialog/useDialog';
import {
    useOnLoginModalClose
} from 'components/loginModal/hooks/useOnLoginModalClose';

interface IReturn {
    isLoggedIn: boolean;
    loginModal: ReactNode;
    showLoginDialog: () => void;
    hideLoginDialog: () => void;
}

export const useLoginModal = (): IReturn => {
    const { session } = useSession();
    const isLoggedIn = !!session;

    const { handleAfterClose } = useOnLoginModalClose();

    const handleClose = () => {
        hideLoginDialog();
        handleAfterClose();
    };

    const {
        component: loginModal,
        show: showLoginDialog,
        hide: hideLoginDialog,
    } = useDialog({
        content: <LoginModal onClose={() => handleClose()} />,
        paperProps: {
            style: {
                width: 400,
                height: 276,
                borderRadius: 16,
            },
        },
        dialogContentProps: {
            sx: {
                padding: 4,
            },
        },
        wrapContentIntoBox: false,
    });

    return {
        isLoggedIn,
        loginModal,
        showLoginDialog,
        hideLoginDialog
    };
};
