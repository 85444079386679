import { useCallback } from 'react';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { getQueryFilterDataToSef } from 'components/advertisementsFilter/features/search/api/dataToSef/getQueryFilterDataToSef';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';
import { IFilterUrlData } from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/IFilterUrlData';

interface IReturn {
    getDataToSef: () => Promise<IFilterUrlData>;
}

export const useDataToSef = (): IReturn => {

    const selectedLocation = useLocationStore((store) => store.selectedLocation);
    const selectedCategories = useCategoriesStore((store) => store.selectedCategories);
    const selectedTransaction = useTransactionStore((store) => store.selectedTransaction);

    const getDataToSef = useCallback(async (): Promise<IFilterUrlData> => {
        let dataToSef: IFilterUrlData;
        try {
            dataToSef = await getQueryFilterDataToSef(
                selectedLocation ? selectedLocation.id : undefined,
                selectedCategories.length && selectedCategories.length === 1
                    ? selectedCategories[0].id
                    : undefined,
                selectedTransaction ? selectedTransaction.id : undefined,
            );

            return dataToSef;
        } catch (error) {
            console.error(error);
        }

        return dataToSef;
    }, [selectedLocation, selectedCategories, selectedTransaction]);

    return {
        getDataToSef,
    };
};