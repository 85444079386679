import {SearchFilterHistogramFilterDtoTransactionEnum} from 'modules/api/generated';
import {ETransaction} from 'modules/api/clients/advertisement/common/enums/parameters/transaction/ETransaction';

export const transactionEnumsToDtoRecord: Record<ETransaction, SearchFilterHistogramFilterDtoTransactionEnum> = {
    [ETransaction.PURCHASE]: SearchFilterHistogramFilterDtoTransactionEnum.Purchase,
    [ETransaction.HIREOUT]: SearchFilterHistogramFilterDtoTransactionEnum.Hireout,
    [ETransaction.RENT]: SearchFilterHistogramFilterDtoTransactionEnum.Rent,
    [ETransaction.EXCHANGE]: SearchFilterHistogramFilterDtoTransactionEnum.Exchange,
    [ETransaction.AUCTION]: SearchFilterHistogramFilterDtoTransactionEnum.Auction,
    [ETransaction.SALE]: SearchFilterHistogramFilterDtoTransactionEnum.Sale,
};