import { FC } from 'react';
import { Box } from '@mui/material';
import { SearchButton } from 'components/advertisementsFilter/components/common/searchButton/SearchButton';
import { ExtendedFilterButton } from 'components/advertisementsFilter/components/desktop/partials/buttons/partials/extendedFilterButton/ExtendedFilterButton';

interface IButtons {
    isActive: boolean;
    onRedirectByFilter: () => void;
    openExtendedFilter: () => void;
    isExtendedFilterOpened: boolean;
}

export const Buttons: FC<IButtons> = ({
    isActive,
    openExtendedFilter,
    onRedirectByFilter,
    isExtendedFilterOpened,
}) => {

    return <Box
        gap={1}
        display='flex'
        paddingRight={2}
        alignItems='center'
    >
        <ExtendedFilterButton
            isActive={isActive}
            onClick={openExtendedFilter}
            isOpened={isExtendedFilterOpened}
        />

        <SearchButton onClick={onRedirectByFilter}/>
    </Box>;
};
