import {SearchFilterHistogramFilterDtoCategoriesEnum} from 'modules/api/generated';
import {ESubCategory} from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';

export const subCategoryEnumsToDtoRecord: Record<ESubCategory, SearchFilterHistogramFilterDtoCategoriesEnum> = {
    [ESubCategory.STUDIO_APARTMENT]: SearchFilterHistogramFilterDtoCategoriesEnum.StudioApartment,
    [ESubCategory.DOUBLE_STUDIO_APARTMENT]: SearchFilterHistogramFilterDtoCategoriesEnum.DoubleStudioApartment,
    [ESubCategory.ONE_ROOM_APARTMENT]: SearchFilterHistogramFilterDtoCategoriesEnum.OneRoomApartment,
    [ESubCategory.TWO_ROOM_APARTMENT]: SearchFilterHistogramFilterDtoCategoriesEnum.TwoRoomApartment,
    [ESubCategory.THREE_ROOM_APARTMENT]: SearchFilterHistogramFilterDtoCategoriesEnum.ThreeRoomApartment,
    [ESubCategory.FOUR_ROOM_APARTMENT]: SearchFilterHistogramFilterDtoCategoriesEnum.FourRoomApartment,
    [ESubCategory.FIVE_PLUS_ROOM_APARTMENT]: SearchFilterHistogramFilterDtoCategoriesEnum.FivePlusRoomApartment,
    [ESubCategory.HOLIDAY_APARTMENT]: SearchFilterHistogramFilterDtoCategoriesEnum.HolidayApartment,
    [ESubCategory.LOFT]: SearchFilterHistogramFilterDtoCategoriesEnum.Loft,
    [ESubCategory.MAISONETTE]: SearchFilterHistogramFilterDtoCategoriesEnum.Maisonette,
    [ESubCategory.OTHER_TYPE_OF_APARTMENT]: SearchFilterHistogramFilterDtoCategoriesEnum.OtherTypeOfApartment,
    [ESubCategory.FAMILY_HOUSE]: SearchFilterHistogramFilterDtoCategoriesEnum.FamilyHouse,
    [ESubCategory.COUNTRY_HOUSE]: SearchFilterHistogramFilterDtoCategoriesEnum.CountryHouse,
    [ESubCategory.FARM_SETTLEMENT]: SearchFilterHistogramFilterDtoCategoriesEnum.FarmSettlement,
    [ESubCategory.MOBILE_HOUSE]: SearchFilterHistogramFilterDtoCategoriesEnum.MobileHouse,
    [ESubCategory.HOUSEBOAT]: SearchFilterHistogramFilterDtoCategoriesEnum.Houseboat,
    [ESubCategory.OTHER_OBJECT_FOR_HOUSING]: SearchFilterHistogramFilterDtoCategoriesEnum.OtherObjectForHousing,
    [ESubCategory.CABIN_AND_LOG_CABIN]: SearchFilterHistogramFilterDtoCategoriesEnum.CabinAndLogCabin,
    [ESubCategory.COTTAGE_AND_RECREATION_HOUSE]: SearchFilterHistogramFilterDtoCategoriesEnum.CottageAndRecreationHouse,
    [ESubCategory.GARDEN_HUT]: SearchFilterHistogramFilterDtoCategoriesEnum.GardenHut,
    [ESubCategory.OTHER_RECREATIONAL_OBJECT]: SearchFilterHistogramFilterDtoCategoriesEnum.OtherRecreationalObject,
    [ESubCategory.LAND_FOR_FAMILY_HOUSE]: SearchFilterHistogramFilterDtoCategoriesEnum.LandForFamilyHouse,
    [ESubCategory.RECREATIONAL_LAND]: SearchFilterHistogramFilterDtoCategoriesEnum.RecreationalLand,
    [ESubCategory.LAND_FOR_HOUSING_CONSTRUCTION]: SearchFilterHistogramFilterDtoCategoriesEnum.LandForHousingConstruction,
    [ESubCategory.LAND_FOR_CIVIC_AMENITIES]: SearchFilterHistogramFilterDtoCategoriesEnum.LandForCivicAmenities,
    [ESubCategory.COMMERCIAL_ZONE]: SearchFilterHistogramFilterDtoCategoriesEnum.CommercialZone,
    [ESubCategory.INDUSTRIAL_ZONE]: SearchFilterHistogramFilterDtoCategoriesEnum.IndustrialZone,
    [ESubCategory.MIXED_ZONE]: SearchFilterHistogramFilterDtoCategoriesEnum.MixedZone,
    [ESubCategory.GARDEN]: SearchFilterHistogramFilterDtoCategoriesEnum.Garden,
    [ESubCategory.ORCHARD]: SearchFilterHistogramFilterDtoCategoriesEnum.Orchard,
    [ESubCategory.VINEYARD_AND_HOP_GARDEN]: SearchFilterHistogramFilterDtoCategoriesEnum.VineyardAndHopGarden,
    [ESubCategory.MEADOW_AND_PASTURE]: SearchFilterHistogramFilterDtoCategoriesEnum.MeadowAndPasture,
    [ESubCategory.ARABLE_LAND]: SearchFilterHistogramFilterDtoCategoriesEnum.ArableLand,
    [ESubCategory.FOREST_LAND]: SearchFilterHistogramFilterDtoCategoriesEnum.ForestLand,
    [ESubCategory.POND_AND_LAKE]: SearchFilterHistogramFilterDtoCategoriesEnum.PondAndLake,
    [ESubCategory.LAND_FOR_ADVERTISING]: SearchFilterHistogramFilterDtoCategoriesEnum.LandForAdvertising,
    [ESubCategory.OTHER_TYPE_OF_LAND]: SearchFilterHistogramFilterDtoCategoriesEnum.OtherTypeOfLand,
    [ESubCategory.OFFICES]: SearchFilterHistogramFilterDtoCategoriesEnum.Offices,
    [ESubCategory.BUSINESS_SPACES]: SearchFilterHistogramFilterDtoCategoriesEnum.BusinessSpaces,
    [ESubCategory.RESTAURANT_SPACES]: SearchFilterHistogramFilterDtoCategoriesEnum.RestaurantSpaces,
    [ESubCategory.STORAGE_AREAS]: SearchFilterHistogramFilterDtoCategoriesEnum.StorageAreas,
    [ESubCategory.SPACE_FOR_PRODUCTION]: SearchFilterHistogramFilterDtoCategoriesEnum.SpaceForProduction,
    [ESubCategory.REPAIR_AREA]: SearchFilterHistogramFilterDtoCategoriesEnum.RepairArea,
    [ESubCategory.SPORTS_FACILITIES]: SearchFilterHistogramFilterDtoCategoriesEnum.SportsFacilities,
    [ESubCategory.SPACE_FOR_ADVERTISEMENT]: SearchFilterHistogramFilterDtoCategoriesEnum.SpaceForAdvertisement,
    [ESubCategory.OTHER_TYPE_OF_SPACE]: SearchFilterHistogramFilterDtoCategoriesEnum.OtherTypeOfSpace,
    [ESubCategory.APARTMENT_HOUSE]: SearchFilterHistogramFilterDtoCategoriesEnum.ApartmentHouse,
    [ESubCategory.RENTAL_HOUSE]: SearchFilterHistogramFilterDtoCategoriesEnum.RentalHouse,
    [ESubCategory.OFFICE_BUILDING]: SearchFilterHistogramFilterDtoCategoriesEnum.OfficeBuilding,
    [ESubCategory.COMMERCIAL_OBJECT]: SearchFilterHistogramFilterDtoCategoriesEnum.CommercialObject,
    [ESubCategory.RESTAURANT]: SearchFilterHistogramFilterDtoCategoriesEnum.Restaurant,
    [ESubCategory.POLYFUNCTIONAL_BUILDING]: SearchFilterHistogramFilterDtoCategoriesEnum.PolyfunctionalBuilding,
    [ESubCategory.WAREHOUSE]: SearchFilterHistogramFilterDtoCategoriesEnum.Warehouse,
    [ESubCategory.MANUFACTURING_FACILITY]: SearchFilterHistogramFilterDtoCategoriesEnum.ManufacturingFacility,
    [ESubCategory.REPAIR_FACILITY]: SearchFilterHistogramFilterDtoCategoriesEnum.RepairFacility,
    [ESubCategory.OBJECT_FOR_BREEDING_ANIMALS]: SearchFilterHistogramFilterDtoCategoriesEnum.ObjectForBreedingAnimals,
    [ESubCategory.HOTEL_AND_PENSION]: SearchFilterHistogramFilterDtoCategoriesEnum.HotelAndPension,
    [ESubCategory.SPA]: SearchFilterHistogramFilterDtoCategoriesEnum.Spa,
    [ESubCategory.HISTORICAL_OBJECT]: SearchFilterHistogramFilterDtoCategoriesEnum.HistoricalObject,
    [ESubCategory.COMMERCIAL_FACILITY]: SearchFilterHistogramFilterDtoCategoriesEnum.CommercialFacility,
    [ESubCategory.SPORT_OBJECT]: SearchFilterHistogramFilterDtoCategoriesEnum.SportObject,
    [ESubCategory.SMALL_POWER_STATION]: SearchFilterHistogramFilterDtoCategoriesEnum.SmallPowerStation,
    [ESubCategory.GAS_STATION]: SearchFilterHistogramFilterDtoCategoriesEnum.GasStation,
    [ESubCategory.MOBILE_CELLS_AND_STANDS]: SearchFilterHistogramFilterDtoCategoriesEnum.MobileCellsAndStands,
    [ESubCategory.OTHER_TYPE_OF_OBJECT]: SearchFilterHistogramFilterDtoCategoriesEnum.OtherTypeOfObject,
    [ESubCategory.DETACHED_GARAGE]: SearchFilterHistogramFilterDtoCategoriesEnum.DetachedGarage,
    [ESubCategory.CONSOLIDATED_GROUND]: SearchFilterHistogramFilterDtoCategoriesEnum.ConsolidatedGround,
    [ESubCategory.AGRICULTURAL_OBJECT]: SearchFilterHistogramFilterDtoCategoriesEnum.AgriculturalObject,
    [ESubCategory.OTHER_LAND_TO_BUILDING]: SearchFilterHistogramFilterDtoCategoriesEnum.OtherLandToBuilding,
    [ESubCategory.OTHER_AGRICULTURAL_LAND]: SearchFilterHistogramFilterDtoCategoriesEnum.OtherAgriculturalLand,
    [ESubCategory.SECONDARY_STUDIO_APARTMENT]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryStudioApartment,
    [ESubCategory.SECONDARY_THREE_ROOM_APARTMENT]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryThreeRoomApartment,
    [ESubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryLandForFamilyHouses,
    [ESubCategory.SECONDARY_RECREATIONAL_LAND]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryRecreationalLand,
    [ESubCategory.SECONDARY_COMMERCIAL_PLOTS]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryCommercialPlots,
    [ESubCategory.SECONDARY_GARDEN]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryGarden,
    [ESubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryOrchardsVineyardsHopFields,
    [ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryAgriculturalAndForestLand,
    [ESubCategory.SECONDARY_GARAGE_AND_PARKING]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryGarageAndParking,
    [ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryOfficesAndAdministration,
    [ESubCategory.SECONDARY_THE_SHOP]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryTheShop,
    [ESubCategory.SECONDARY_A_RESTAURANT]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryARestaurant,
    [ESubCategory.SECONDARY_WAREHOUSES]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryWarehouses,
    [ESubCategory.SECONDARY_PRODUCTION]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryProduction,
    [ESubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryAnotherSpaceAndObject,
    [ESubCategory.SECONDARY_HOTEL_GUESTHOUSE]: SearchFilterHistogramFilterDtoCategoriesEnum.SecondaryHotelGuesthouse,
};
