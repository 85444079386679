import { SearchFilterDataDto } from 'modules/api/generated';
import {
    IInitialSearchFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import {
    mapSearchFilterDataCategoryDtoToICategories
} from 'modules/api/clients/advertisement/filter/helpers/mappers/initialFilter/category/mapCategorySearchFilterDataDtoToICategories';
import {
    mapSearchFilterDataTransactionDtoToITransaction
} from 'modules/api/clients/advertisement/filter/helpers/mappers/initialFilter/transaction/mapSearchFilterDataTransactionDtoToITransaction';
import {
    mapLocationResponseDtoToILocationSearchFilter
} from 'modules/api/clients/location/helpers/mappers/location/mapLocationResponseDtoToILocationSearchFilter';

export const mapInitialSearchFilterDataDtoToISearchFilterData = (dto: SearchFilterDataDto): IInitialSearchFilterData => ({
    transactions: dto.transactions.map(mapSearchFilterDataTransactionDtoToITransaction),
    categories: mapSearchFilterDataCategoryDtoToICategories(dto.categories),
    locations: dto.locations.map(mapLocationResponseDtoToILocationSearchFilter),
});