import {AdvertisementListingFilterDataDtoTransactionEnum} from 'modules/api/generated';
import {ETransaction} from 'modules/api/clients/advertisement/common/enums/parameters/transaction/ETransaction';

export const transactionEnumsRecord: Record<AdvertisementListingFilterDataDtoTransactionEnum, ETransaction> = {
    [AdvertisementListingFilterDataDtoTransactionEnum.Purchase]: ETransaction.PURCHASE,
    [AdvertisementListingFilterDataDtoTransactionEnum.Hireout]: ETransaction.HIREOUT,
    [AdvertisementListingFilterDataDtoTransactionEnum.Rent]: ETransaction.RENT,
    [AdvertisementListingFilterDataDtoTransactionEnum.Exchange]: ETransaction.EXCHANGE,
    [AdvertisementListingFilterDataDtoTransactionEnum.Auction]: ETransaction.AUCTION,
    [AdvertisementListingFilterDataDtoTransactionEnum.Sale]: ETransaction.SALE,
};
