import { EBratislavaAndKosiceSefs } from 'modules/advertisementsFilter/query/enums/EBratislavaAndKosiceSefs';

// pojde prec ked bude novy vypis inzeratov
export const BratislavaAndKosiceLocationIdsToEBratislavaAndKosiceSefs: Record<
    string,
    EBratislavaAndKosiceSefs
> = {
    // Bratislava
    '100012520': EBratislavaAndKosiceSefs.BRATISLAVA_LAMAC,
    '100012510': EBratislavaAndKosiceSefs.BRATISLAVA_PODUNAJSKE,
    '100012513': EBratislavaAndKosiceSefs.BRATISLAVA_NOVE_MESTO,
    '100012519': EBratislavaAndKosiceSefs.BRATISLAVA_DEVIN,
    '100012518': EBratislavaAndKosiceSefs.BRATISLAVA_KARLOVA_VES,
    '100012524': EBratislavaAndKosiceSefs.BRATISLAVA_PETRZALKA,
    '100012522': EBratislavaAndKosiceSefs.BRATISLAVA_CUNOVO,
    '100012514': EBratislavaAndKosiceSefs.BRATISLAVA_RACA,
    '100012516': EBratislavaAndKosiceSefs.BRATISLAVA_DEVINSKA,
    '100012521': EBratislavaAndKosiceSefs.BRATISLAVA_ZAHORSKA,
    '100012511': EBratislavaAndKosiceSefs.BRATISLAVA_RUZINOV,
    '100012439': EBratislavaAndKosiceSefs.BRATISLAVA_BRATISLAVA_1,
    '100012525': EBratislavaAndKosiceSefs.BRATISLAVA_RUSOVCE,
    '100012512': EBratislavaAndKosiceSefs.BRATISLAVA_VRAKUNA,
    '100012523': EBratislavaAndKosiceSefs.BRATISLAVA_JAROVCE,
    '100012515': EBratislavaAndKosiceSefs.BRATISLAVA_VAJNORY,
    '100012517': EBratislavaAndKosiceSefs.BRATISLAVA_DUBRAVKA,

    // Kosice
    '100012911': EBratislavaAndKosiceSefs.KOSICE_ZAPAD,
    '100012912': EBratislavaAndKosiceSefs.KOSICE_HRDINOV,
    '100012926': EBratislavaAndKosiceSefs.KOSICE_DZUNGLA,
    '100012905': EBratislavaAndKosiceSefs.KOSICE_TAHANOVCE,
    '100012913': EBratislavaAndKosiceSefs.KOSICE_NOVA_VES,
    '100012928': EBratislavaAndKosiceSefs.KOSICE_LUNIK,
    '100012910': EBratislavaAndKosiceSefs.KOSICE_MYSLAVA,
    '100012920': EBratislavaAndKosiceSefs.KOSICE_NAD_JAZEROM,
    '100012925': EBratislavaAndKosiceSefs.KOSICE_SIDLISKO_KVP,
    '100012924': EBratislavaAndKosiceSefs.KOSICE_SIDLISKO_TAHANOVCE,
    '100012923': EBratislavaAndKosiceSefs.KOSICE_POLOV,
    '100012918': EBratislavaAndKosiceSefs.KOSICE_SEBASTOVCE,
    '100012914': EBratislavaAndKosiceSefs.KOSICE_BARCA,
    '100012906': EBratislavaAndKosiceSefs.KOSICE_SEVER,
    '100012919': EBratislavaAndKosiceSefs.KOSICE_KRASNA,
    '100012908': EBratislavaAndKosiceSefs.KOSICE_LORINCIK,
    '100012904': EBratislavaAndKosiceSefs.KOSICE_KAVECANY,
    '100012922': EBratislavaAndKosiceSefs.KOSICE_SACA,
    '100012927': EBratislavaAndKosiceSefs.KOSICE_VYSNE,
    '100012907': EBratislavaAndKosiceSefs.KOSICE_STARE_MESTO,
    '100012909': EBratislavaAndKosiceSefs.KOSICE_PERES,
    '100012921': EBratislavaAndKosiceSefs.KOSICE_JUH,

    // Ine
    '100010601': EBratislavaAndKosiceSefs.OSTROV_PIESTANY,
    '2J163XQB288yUsTWV1On': EBratislavaAndKosiceSefs.OSTROV_PIESTANY,
};
