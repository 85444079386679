import { SearchFilterDataLocationDto } from 'modules/api/generated';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { ELocationType } from 'modules/api/clients/location/enums/type/ELocationType';

export const mapLocationResponseDtoToILocationSearchFilter = (
    dto: SearchFilterDataLocationDto,
): ILocation => ({
    id: dto.id,
    name: dto.name,
    sefName: dto.sefName,
    type: ELocationType[dto.type],
});