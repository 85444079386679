import { FC, useCallback, useState } from 'react';
import {
    MobileFilterHeader
} from 'components/advertisementsFilter/components/mobile/homePage/partilas/filterHeader/FilterHeader';
import {
    ExtendedMobileFilterDialog
} from 'components/advertisementsFilter/components/extendedFilter/mobile/ExtendedMobileFilterDialog';

export const MobileFilter: FC = () => {
    const [isFilterOpened, setIsFilterOpened] = useState(false);

    const toggleFilter = useCallback((flag: boolean) => {
        setIsFilterOpened(flag);
    }, []);

    return <>
        <MobileFilterHeader
            toggleFilter={toggleFilter}
        />

        {isFilterOpened &&
            <ExtendedMobileFilterDialog
                toggleExtendedFilter={toggleFilter}
                isExtendedFilterOpened={isFilterOpened}
                onCloseFilter={() => toggleFilter(false)}
            />
        }
    </>;
};