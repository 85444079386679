import {
    AdvertisementListingFilterPaginationDto,
} from 'modules/api/generated';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';
import { orderEnumToDtoRecord } from 'modules/api/clients/advertisement/listing/enums/records/order/orderEnumToDtoRecord';

export const mapIAdvertisementListingFilterPaginationToAdvertisementListingFilterPaginationDto = (
    pagination: IAdvertisementListingFilterPagination,
): AdvertisementListingFilterPaginationDto => ({
    limit: pagination.limit,
    skip: pagination.skip,
    order: orderEnumToDtoRecord[pagination.order],
});