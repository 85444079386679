import { SearchFilterDataLocationDto } from 'modules/api/generated';
import {
    IFilterUrlDataLocation
} from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/partials/location/IFilterUrlDataLocation';
import {
    mapLocationResponseDtoToILocation
} from 'modules/api/clients/location/helpers/mappers/location/mapLocationResponseDtoToILocation';
import { ELocationType } from 'modules/api/clients/location/enums/type/ELocationType';

export const mapFilterUrlDataLocationToIFilterUrlDataLocation = (
    dto: SearchFilterDataLocationDto
): IFilterUrlDataLocation => ({
    id: dto.id,
    name: dto.name,
    sefName: dto.sefName,
    type: ELocationType[dto.type],
    ...(!!dto.parent && ({parent: mapLocationResponseDtoToILocation(dto.parent)})),
});