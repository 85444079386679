import { HistogramDataItemDto } from 'modules/api/generated';
import {
    IHistogramDataItem
} from 'modules/api/clients/advertisement/filter/interfaces/partials/histogram/partials/item/IHistogramDataItem';

export const mapHistogramDataItemDtoToIHistogramDataItem = (dto: HistogramDataItemDto): IHistogramDataItem => ({
    from: dto.from,
    to: dto.to,
    count: dto.count,
    percent: dto.percent,
});