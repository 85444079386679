import { ELabel } from 'modules/gtmEvents/enums/homePage/ELabel';
import { EGtmEvent} from 'modules/gtmEvents/enums/EGtmEvent';
import { ECategory } from 'modules/gtmEvents/enums/homePage/ECategory';
import { EAction } from 'modules/gtmEvents/enums/homePage/EAction';
import { IHomePageTrackEvent } from 'modules/gtmEvents/interfaces/homePage/IHomePageTrackEvent';
import { IHomePageSearchEvent } from 'modules/gtmEvents/interfaces/homePage/IHomePageSearchEvent';
import { IHomePageDevProject } from 'modules/api/clients/homePage/interfaces/devProjects/IHomePageDevProject';
import { ECategoryLabelRecord } from 'components/page/homePage/components/devProjects/common/helpers/ECategoryLabelRecord';
import { IHomePageEcommerceEvent } from 'modules/gtmEvents/interfaces/homePage/IHomePageEcommerceEvent';
import { EItemListId } from 'modules/gtmEvents/enums/EItemListId';
import { EItemBrand } from 'modules/gtmEvents/enums/EItemBrand';
import { IAdvertisementListingFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/data/IAdvertisementListingFilterData';
import { IHomePageDevProjectAdvertisementItem } from 'modules/gtmEvents/interfaces/homePage/IHomePageDevProjectAdvertisementItem';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { priceCurrencyEnumsRecord } from 'modules/api/clients/advertisement/common/constants/records/parameters/price/priceCurrencyEnumsRecord';
import { ILocation as DevProjectsILocation } from 'modules/api/clients/homePage/interfaces/devProjects/ILocation';
import { DtoTransactionEnumToETransaction } from 'components/page/realEstate/common/advertisements/constants/parameters/DtoTransactionEnumToETransaction';
import { ECreativeSlot } from 'modules/gtmEvents/enums/ECreativeSlot';
import { EPromotionId } from 'modules/gtmEvents/enums/EPromotionId';
import { EPromotionName } from 'modules/gtmEvents/enums/EPromotionName';
import { ECreativeName } from 'modules/gtmEvents/enums/ECreativeName';
import {IAdvertisementItem} from 'modules/gtmEvents/interfaces/view/advertisement/partials/IAdvertisementItem';
import { IHomePageSelectPromotionEvent } from 'modules/gtmEvents/interfaces/homePage/IHomePageSelectPromotionEvent';
import { IHomePageSelectItemEvent } from 'modules/gtmEvents/interfaces/homePage/IHomePageSelectItemEvent';
import {
    apiCategoryToECategoryNameRecord
} from 'components/advertisementsFilter/modules/categories/records/apiCategoryToECategoryNameRecord';
import {
    getItemVariant,
} from 'modules/gtmEvents/helpers/gtmHelper';
import { ESefCategories } from 'components/page/homePage/enums/categorySefs/ESefCategories';

const parseSearchEventProperty = (currentFilterCategories: (EMainCategory | ESubCategory)[]) => {
    const property = currentFilterCategories?.map((item) => {
        return apiCategoryToECategoryNameRecord[item];
    });

    return property?.join(',') || '';
};
const parseAdvancedSearchEventProperty = (
    filter?: IAdvertisementListingFilterData
) => {
    const defaultValue = '';

    const properties = [
        filter?.priceFrom || defaultValue,
        filter?.priceTo || defaultValue,
        filter?.areaFrom || defaultValue,
        filter?.areaTo || defaultValue,
        filter?.onlyNewbuilding ? 'novostavby' : defaultValue,
        filter?.onlyWithVideo ? 'video-prehliadka' : defaultValue,
        filter?.fulltext || defaultValue,
    ].join(';');

    return properties + ';';
};

const parseLocationString = (location: DevProjectsILocation): string => {
    const locationArray: string[] = [];

    locationArray.push(location?.country ? location.country : '');
    locationArray.push(location?.county ? location.county : '');
    locationArray.push(location?.district ? location.district : '');
    locationArray.push(location?.city ? location.city : '');

    return locationArray.join(',');
};

const reversedCurrencyRecord = Object.fromEntries(
    Object.entries(priceCurrencyEnumsRecord).map(([key, value]) => [
        value,
        key,
    ]),
);

export const getAdvertisementServicesClickEvent = (gtmLabel: ELabel, isLoggedIn?: boolean): IHomePageTrackEvent => {
    return {
        event: EGtmEvent.TRACK_EVENT,
        category: ECategory.HP,
        action: EAction.BUTTON,
        label: gtmLabel,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
    };
};

export const getLocationSearchEvent = (sefName: string, action: EAction, isLoggedIn?: boolean): IHomePageSearchEvent => {
    return {
        event: EGtmEvent.FILTER,
        category: ECategory.HPFILTER,
        action,
        first: sefName,
        second: '',
        third: '',
        transactionId: '',
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
    };
};

export const getSearchEvent = (
    currentFilter: IAdvertisementListingFilterData,
    locationSef: string,
    transactionId?: number,
    isHomePage?: boolean,
    isLoggedIn?: boolean,
): IHomePageSearchEvent => {
    return {
        event: EGtmEvent.FILTER,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
        category: isHomePage ? ECategory.HPFILTER : ECategory.VYSLEDKY_FILTER,
        action: EAction.ADVERTISING,
        first: locationSef ? locationSef : '',
        second: parseSearchEventProperty(currentFilter?.categories),
        third: currentFilter?.transaction ? DtoTransactionEnumToETransaction[currentFilter?.transaction]?.toLowerCase() : '',
        transactionId: transactionId ? String(transactionId) : '',
        fourth: parseAdvancedSearchEventProperty(currentFilter),
    };
};

export const getMyAccountClickEvent = (isLoggedIn?: boolean): IHomePageTrackEvent => {
    return {
        event: EGtmEvent.TRACK_EVENT,
        category: ECategory.HP,
        action: EAction.BUTTON,
        label: ELabel.LOGIN,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
    };
};

export const getEcommerceFromAdvertisement = (devProjects: IHomePageDevProject[]): IHomePageDevProjectAdvertisementItem[] => {
    return devProjects?.map((item, index) => {
        const price = String(item.priceInfo.price).match(/\d/g)?.join('');
        const newECommerceItem = {
            item_id: item.id,
            item_name: item.title,
            price: price ? price : '',
            currency: price ? reversedCurrencyRecord[item.priceInfo.price?.split(' ')?.at(-1)] : '',
            item_category: ECategoryLabelRecord[item.advertisementInfo.category],
            item_category3: parseLocationString(item.location),
            item_category4: item?.transaction,
            item_category5: item?.realEstateState,
            index: index + 1,
            item_list_id: EItemListId.LATEST_LISTINGS,
            item_list_name: EItemListId.LATEST_LISTINGS,
            item_brand: EItemBrand.CLASSIFIED_LISTING,
            item_variant: getItemVariant(item.photoUrl, false),
            location_id: parseLocationString(item.location),
            quantity: 1
        };
        return newECommerceItem;
    });
};

export const getEcommerceItemFromAdvertisement = (devProject: IHomePageDevProject, index: number): IAdvertisementItem => {
    const price = String(devProject.priceInfo.price).match(/\d/g)?.join('');
    return {
        item_id: devProject.id,
        item_name: devProject.title,
        affiliation: devProject.advertiser?.agencyName !== '' ? devProject.advertiser.agencyName : devProject.advertiser.name,
        index,
        item_brand: EItemBrand.CLASSIFIED_LISTING,
        item_category: ECategoryLabelRecord[devProject.advertisementInfo.category],
        item_category3: parseLocationString(devProject.location),
        item_category4: devProject?.transaction,
        item_category5: devProject?.realEstateState,
        item_list_id: EItemListId.LATEST_LISTINGS,
        item_list_name: EItemListId.LATEST_LISTINGS,
        item_variant: getItemVariant(devProject.photoUrl, false),
        location_id: parseLocationString(devProject.location),
        price: price ? price : '',
        currency: price ? reversedCurrencyRecord[devProject.priceInfo.price?.split(' ')?.at(-1)] : '',
        quantity: 1
    };
};


export const getViewDevProjectsEvent = (devProjects: IHomePageDevProject[]): IHomePageEcommerceEvent => {

    return {
        event: EGtmEvent.VIEW_ITEM_LIST,
        ecommerce: {
            item_list_id: EItemListId.LATEST_LISTINGS,
            item_list_name: EItemListId.LATEST_LISTINGS,
            items: getEcommerceFromAdvertisement(devProjects)
        }
    };
};

export const getPromoDevProjectsEvent = (devProjects: IHomePageDevProject[]): IHomePageEcommerceEvent => {
    return {
        event: EGtmEvent.VIEW_PROMOTION,
        ecommerce: {
            item_list_id: EItemListId.LATEST_LISTINGS,
            item_list_name: EItemListId.LATEST_LISTINGS,
            items: getEcommerceFromAdvertisement(devProjects)
        }
    };
};

export const getSelectPromotionEvent = (devProject: IHomePageDevProject, index: number): IHomePageSelectPromotionEvent => {
    let promotionId = null;
    if (devProject.flags?.isPremium) {
        promotionId = EPromotionId.PREMIUM_OF_LIST;
    }
    if (devProject.flags?.isTop) {
        promotionId = EPromotionId.TOP_OF_LIST;
    }

    return {
        event: EGtmEvent.SELECT_PROMOTION,
        ecommerce: {
            creative_slot: ECreativeSlot.CAROUSEL,
            creative_name: ECreativeName.EXCLUSIVE,
            promotion_id: promotionId,
            promotion_name: EPromotionName.FEATURED_LISTINGS,
            items: [
                getEcommerceItemFromAdvertisement(devProject, index)
            ]
        }
    };
};

export const getSelectItemEvent = (devProject: IHomePageDevProject, index: number): IHomePageSelectItemEvent => {
    return {
        event: EGtmEvent.SELECT_ITEM,
        ecommerce: {
            item_list_id: EItemListId.LATEST_LISTINGS,
            item_list_name: EItemListId.LATEST_LISTINGS,
            items: [
                getEcommerceItemFromAdvertisement(devProject, index)
            ]
        }
    };
};

export const getClickOnUserNameEvent = (): IHomePageTrackEvent => {
    return {
        event: EGtmEvent.TRACK_EVENT,
        category: ECategory.HP,
        action: EAction.MY_ACCOUNT,
        label: ELabel.CLICK_ON_NAME,
        is_loggedin: 'true',
    };
};

export const getClickOnShowOnMapEvent = (isLoggedIn: boolean): IHomePageTrackEvent => {
    return {
        event: EGtmEvent.TRACK_EVENT,
        category: ECategory.HP,
        action: EAction.LIVE_IN_NEW_BUILDING,
        label: ELabel.SHOW_ON_MAP,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
    };
};

export const getClickOnCategoryEvent = (isLoggedIn: boolean, mainCategory: ESefCategories, subCategory?: ESefCategories): IHomePageTrackEvent => {
    return {
        event: EGtmEvent.TRACK_EVENT,
        category: ECategory.HP,
        action: EAction.WHAT_HOUSING_YOU_LOOKING_FOR,
        label: (subCategory ? `${mainCategory}-${subCategory}` : mainCategory) as unknown as ELabel,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
    };
};

export const getClickOnPopularRegionsSKEvent = (sef: string, isLoggedIn: boolean): IHomePageTrackEvent => {
    return {
        event: EGtmEvent.TRACK_EVENT,
        category: ECategory.HP,
        action: EAction.POPULAR_REGIONS_SK,
        label: sef as ELabel,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
    };
};
