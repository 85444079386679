import React, { FC } from 'react';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { ButtonsBox } from 'components/loginModal/partials/content/partials/buttonsBox/ButtonsBox';
import brandLogo from 'components/loginModal/img/svg/brandLogo.svg';

interface IProps {
    onClose?: () => void;
}

export const Content: FC<IProps> = ({ onClose }) => {
    return (
        <>
            <Box onClick={onClose} sx={{ cursor: 'pointer', width: 'fit-content', height: 'fit-content' }}>
                <Svg icon component={CloseIcon} width={24}/>
            </Box>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={1} marginTop={-1}>
                <Svg component={brandLogo} width={28} height={32}/>
                <Text variant={'h4'} semibold>
                    Prihláste sa
                </Text>
                <Text variant={'body3'}>
                    Pre pokračovanie sa, prosím, prihláste.
                </Text>
            </Box>
            <Box marginTop={2}>
                <ButtonsBox />
            </Box>
        </>
    );
};
